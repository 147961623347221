import React, { useState, forwardRef } from 'react'; // Import forwardRef here
import './FAQ.css'; // Make sure to create this CSS file

const FAQ = forwardRef(({ ...props }, ref) => { // Use forwardRef to get the ref
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "How does ModifyME work?",
            answer: "We use advanced AI algorithms to transform your photos based on the style you select."
        },
        {
            question: "How much does it cost?",
            answer: "You pay per generation. Each generation costs 0.99€. You will receive 4 generated images."
        },
        {
            question: "What type of photo should I upload for creating an AI character?",
            answer: "Please provide a close-up selfie. Ensure that the photo has good variety and clarity. Avoid wearing sunglasses or hats, and make sure your face is fully visible without any part being obscured or cut off. The better the photo, the better the results!"
        },
        {
            question: "How long does it take to generate my AI character?",
            answer: "It takes around 15 minutes to generate your AI character. You will receive an email with the generated images."
        },
        {
            question: "Do you store my photos?",
            answer: "No, we do not store your photos. We only use your photos to generate your AI character. Once the generation is complete, we delete your photos. No data is stored on our servers."
        },
        {
            question: "Can I upload or generate photos of kids or minors?",
            answer: "Uploading or generating photos of children or minors is strictly forbidden. Our service is intended solely for legal adults, aged 18 and over. Please be aware that when using our service to generate images, you become fully responsible for the content created. We do not take responsibility for generated content. Additionally, it's important to note that AI algorithms can yield unpredictable results with images of children, so such practices should be strictly avoided."
        },
        {
            question: "I got offensive results. I am not okay with this.",
            answer: "We are sorry to hear that. We do not intend to offend anyone. Our AI algorithms are trained on a large dataset of images. Sometimes, AI algorithms can yield unpredictable results. We are constantly working to improve our algorithms. If you are afraid of getting offensive results, please do not use our service. We do not take responsibility for generated content."
        },
        {
            question: "Is it possible to get a refund?",
            answer: "We do not offer refunds. When you purchase a generation, you are paying for the processing time of our servers. Once the generation is complete, we cannot refund the processing time. If you are not satisfied with the results, you can try again with a different photo."
        },
        {
            question: "Is the payment secure?",
            answer: "Yes, the payment is secure. We use Stripe to process payments. Stripe is a leading payment processor used by millions of businesses worldwide."
        },
        {
            question: "Who are the people used in the examples?",
            answer: "The people used in the examples are not real people. They are generated using AI algorithms. You can learn more about this at https://thispersondoesnotexist.com/"
        },
        // ... Add more FAQs here
    ];

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container" ref={ref}> {/* Attach the ref here */}
            <h2>Frequently Asked Questions</h2>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index}>
                        <div className="faq-question" onClick={() => toggleFAQ(index)}>
                            {faq.question}
                            <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        <div className="faq-answer" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default FAQ;