import React from 'react';
import './AlertComponent.css';

const AlertComponent = ({ message, isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
        <div className="alert-overlay">
            <div className="alert-container">
                <p className="alert-message">{message}</p>
                <button className="alert-close" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default AlertComponent;
