import React from 'react';
import './FilterButtons.css'; // Make sure to create this CSS file

const FilterButtons = ({ activeFilter, onFilterChange }) => {
  const filters = ['Anime', 'Cartoon', 'Punk']; // Add more filters as needed

  return (
    <div className="filter-container">
      <h2>Examples</h2>
      <p className="filter-subtitle">Filter by style:</p>
      <div className="filter-buttons">
        {filters.map((filter, index) => (
          <button
            key={index}
            className={`filter-btn ${activeFilter === filter ? 'active' : ''}`}
            onClick={() => onFilterChange(filter)}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterButtons;
