import React, { useState, useEffect } from 'react';
import FilterButtons from './FilterButtons'; 

import './ImageGrid.css';

const ImageGrid = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [generatedImageSrc, setGeneratedImageSrc] = useState('');
    const [originalImageSrc, setOriginalImageSrc] = useState('');
    const [activeFilter, setActiveFilter] = useState('Anime'); // Assuming 'set1' as default filter

     // Define allImages outside of useEffect
     const allImages = {
        Anime: [
            'images/anime/1/8823ccd0-213c-4dba-a979-b0d82651fc45.png',
            'images/anime/7/f9826a32-e589-474f-8edc-26f1116940cb.png',
            'images/anime/1/f133b46a-efce-45da-8377-3e8566492001.png',
            'images/anime/4/8ece8d17-8d30-4060-ad61-001f04285905.png',
            'images/anime/7/177e6516-a365-44df-9f47-f6c0a74889c1.png',
            'images/anime/2/0f90a3b3-cc83-4c2f-87f7-21a1a146f462.png',
            'images/anime/8/6c8fed86-72a1-4e9c-9c4a-6eaaf065579b.png',
            'images/anime/2/5756ef66-facb-49f3-9eae-26da1fe3dcf3.png',
            'images/anime/5/1502e7a1-5caf-4979-93d1-1ef596caf78f.png',
            'images/anime/1/f81b8f4f-8c90-4c23-a4da-da6be4d14389.png',
            'images/anime/5/a45f09f2-2d3a-4650-b7eb-996e2212c0f6.png',
            'images/anime/3/0ab2a21f-6e66-4206-97cc-0acd16a95c48.png',
            'images/anime/3/4169fd2f-1030-47eb-b69a-753a880702ca.png',
            'images/anime/4/8b02b5bf-3002-46af-940e-d775bc173b5f.png',
            'images/anime/4/f38d1502-a862-40f5-a600-d2f6db3005ee.png',
            'images/anime/1/2895a274-019f-40ce-84c8-8f26cdd1e955.png',
            'images/anime/5/3a089561-ecd8-4027-8b88-c234f42ead2b.png',
            'images/anime/5/6d658195-3fba-4c17-8f4a-b96a7126b3c8.png',
            'images/anime/6/55e58571-3448-4d5f-bd98-b583803576c1.png',
            'images/anime/6/9420b467-65c6-4632-8ba5-04c21aa2b61d.png',
            'images/anime/8/13e0d7ab-18f3-4eaa-bd19-f4b03382eef8.png',
            'images/anime/6/bdb09f5a-57d1-4950-a3ae-1d1a5baa7e92.png',
            'images/anime/3/c5cd4ef2-1041-4666-bd2f-291fa912a7aa.png',
            'images/anime/7/08557b75-e5fa-45fe-8d9b-076928b6a9a9.png',
            'images/anime/6/cc74d6f7-a94f-488d-b0bc-07da11101624.png',
            'images/anime/7/2cf1b1c9-1cc3-451f-8042-7a7036daa4b1.png',
            'images/anime/2/5756ef66-facb-49f3-9eae-26da1fe3dcf3.png',
            'images/anime/8/53efef59-4a6d-44ed-86ed-2c5ee380a496.png',
            'images/anime/2/0f90a3b3-cc83-4c2f-87f7-21a1a146f462.png',
            'images/anime/4/e1199062-0566-4523-94ac-0fb3106be439.png',
            'images/anime/3/735d9a7b-8cc8-4307-b8c1-384bbdd0aab9.png',
            'images/anime/8/88fde4ae-cea0-415d-a164-aa8f59a28fa1.png'
        ],
        Cartoon: [
            'images/cartoon/1/0.png',
            'images/cartoon/6/4f0eba01-ddfc-4316-a709-2be69920e534.png',
            'images/cartoon/2/0fd299e5-2c50-4f9b-a349-b786d040fae2.png',
            'images/cartoon/1/3.png',
            'images/cartoon/4/79a81d5b-4324-4256-8b0a-970f03c50b09.png',
            'images/cartoon/6/6ca58e93-fc97-4dd7-978a-1951d05b4119.png',
            'images/cartoon/1/2.png',
            'images/cartoon/8/b561592c-be33-4124-acf1-478a1f49abei.png',
            'images/cartoon/5/e031016d-57eb-430c-b5e0-1de67e4e6004.png',
            'images/cartoon/3/77b1b2a4-0c42-468f-a0f6-7714e076288f.png',
            'images/cartoon/6/f3823bf8-5d81-48b9-bcdf-947ba2e7082d.png',
            'images/cartoon/2/4c2c807e-c705-4451-a5e7-81e18d0748a2.png',
            'images/cartoon/2/ac285164-93e5-4ba5-94f0-4db0f31ff365.png',
            'images/cartoon/6/5e40ed8e-0e4c-48c9-9e98-b0485baacf14.png',
            'images/cartoon/7/6d22c41a-6fce-4c0e-b841-9a91257cfa40.png',
            'images/cartoon/4/0174283d-9717-4af9-a75b-a32b4b6f4707.png',
            'images/cartoon/8/b561592c-be33-4124-acf1-478a1f49abep.png',
            'images/cartoon/3/76a43dcd-1774-4b3b-a6d1-174950047e18.png',
            'images/cartoon/5/6ee4378c-6998-4de8-ab8c-d1d11f6c7248.png',
            'images/cartoon/7/4c5df538-4a52-4b96-9037-ff7d03b6c0ff.png',
            'images/cartoon/3/0cddb0b7-da3e-4022-86d9-89b0423a5560.png',
            'images/cartoon/2/a88284bd-33cf-4c8c-90ad-da94a30f73ec.png',
            'images/cartoon/1/1.png',
            'images/cartoon/4/6a12ce0c-a884-432f-8c61-60c3464e5df5.png',
            'images/cartoon/5/b4fcf531-ff6a-4b48-836e-a8b07d686e48.png',
            'images/cartoon/3/fec55543-2a1e-441e-9ba2-3037801c1857.png',
            'images/cartoon/8/b561592c-be33-4124-acf1-478a1f49aber.png',
            'images/cartoon/7/80e0b10d-3c6c-443e-bbc7-452c33c5ff3f.png',
            'images/cartoon/4/b9b2b21c-9995-4cab-b642-23d94fe947b2.png',
            'images/cartoon/5/1782b3df-eae9-4638-9a1d-e3a9bca8c33c.png',
            'images/cartoon/8/b561592c-be33-4124-acf1-478a1f49abeu.png',
            'images/cartoon/7/d624adbd-018c-4262-b11c-f4c1aaad1c59.png'
        ],
        Punk: [
            'images/punk/1/25c246db-a722-400a-a2dd-e53b4ca7a2d2.png',
            'images/punk/5/68aa5b91-d593-4cd3-8d7a-aa1c4028cf10.png',
            'images/punk/8/b561592c-be33-4124-acf1-478a1f49abea.png',
            'images/punk/7/b561592c-be33-4124-acf1-478a1f49abee.png',
            'images/punk/1/946c4f3e-cbc4-4c60-8d97-19021b147338.png',
            'images/punk/2/df14005c-d256-4b89-871a-b427556826d2.png',
            'images/punk/3/381d120a-b273-45ec-b868-bc8c29c797c3.png',
            'images/punk/2/a4c8d22d-f59b-4f1a-94f0-509504dbeeb7.png',
            'images/punk/3/d0c7cbb4-b6b4-4576-bed1-660d671bbb7b.png',
            'images/punk/2/a39e8d9a-774e-4a92-a64c-5e29a40ccae0.png',
            'images/punk/8/b561592c-be33-4124-acf1-478a1f49abeb.png',
            'images/punk/5/b4f55bab-a8fb-4ab6-9be9-61427ef5a884.png',
            'images/punk/6/ec8bef3f-0fc6-4283-a8d8-1738aca2d7a7.png',
            'images/punk/4/99078f43-216b-4973-bdf1-0e64a6de0abe.png',
            'images/punk/6/5b0f5199-b320-46d4-bc7d-b6751617bed6.png',
            'images/punk/4/ed7a0cfc-8db5-45f3-a7a3-d08cd5e4d65f.png',
            'images/punk/5/944348b7-71a0-4556-ac99-c6dcba18a9ef.png',
            'images/punk/6/1db38d12-d449-4410-9992-2fc54f02afc4.png',
            'images/punk/8/b561592c-be33-4124-acf1-478a1f49abec.png',
            'images/punk/2/69fa0e8c-34dc-4929-9710-ef2711bd53ef.png',
            'images/punk/1/9f18d80f-a8ba-4343-a3b0-2e61c56180b1.png',
            'images/punk/7/0c4d4ca4-86aa-46b7-8b98-a3076ce552b5.png',
            'images/punk/6/0177173b-952a-4ecf-afde-cd17119c6b91.png',
            'images/punk/4/175af2db-002f-46d5-8175-777f333ab589.png',
            'images/punk/7/670f117e-a756-4eee-81b2-db7785d12eb2.png',
            'images/punk/8/b561592c-be33-4124-acf1-478a1f49abed.png',
            'images/punk/5/83885a3c-f9e7-4739-9671-9bd1e3e847ca.png',
            'images/punk/4/b3c0c2bd-e764-4ef5-8bf3-f44df40bde6d.png',
            'images/punk/1/c04c5830-bdc8-4327-aad0-e43f6ddc48c0.png',
            'images/punk/3/446dfbea-6cff-46f7-a412-4f4304e9aefd.png',
            'images/punk/7/aa8c9a36-05ca-4977-b482-50b7f898af62.png',
            'images/punk/3/36f68199-3041-47fe-a764-5837021c0043.png'
        ],
    };


    useEffect(() => {
        // Preload images
        Object.values(allImages).flat().forEach((imageSrc) => {
            const image = new Image();
            image.src = imageSrc;
            image.src = imageSrc.replace(/[^\/]*$/, '') + 'original.png'; // Preload original image if needed
        });
    }, []);


    const openModal = (imgSrc) => {
        // console.log("Opening modal with image:", imgSrc);
        setGeneratedImageSrc(imgSrc);
        setOriginalImageSrc(imgSrc.replace(/[^\/]*$/, '') + 'original.png');
        setModalOpen(true);
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setModalOpen(false);
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
    };
    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
    };
    
    const renderImages = () => {
        const imageSet = allImages[activeFilter];
        if (!imageSet) {
            console.error(`No image set found for filter: ${activeFilter}`);
            return null; // or some fallback content
        }
    
        return imageSet.map((imgSrc, index) => (
            <div key={index} className="image-card" onClick={() => openModal(imgSrc)}>
                <img src={imgSrc} alt={`Generated Image ${index}`} />
            </div>
        ));
    };

    return (
        <div className="container-images-grid">
            {/* Filter Menu */}
            <FilterButtons activeFilter={activeFilter} onFilterChange={handleFilterChange} />

            {/* Images Grid */}
            <div className="images-grid">
                {renderImages()}
            </div>

            {/* Modal */}
            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <div className="modal-image-container">
                            <div className="image-wrapper">
                                <h3 className="image-title">Original</h3>
                                <img id="originalImage" src={originalImageSrc} alt="Original Image"/>
                            </div>
                            <div className="image-wrapper">
                                <h3 className="image-title">Generated</h3>
                                <img id="generatedImage" src={generatedImageSrc} alt="Generated Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
   

export default ImageGrid;
