import React from 'react';
import './TopMenu.css'; // Ensure to create this CSS file

const TopMenu = ({ onFAQClick, onTopClick }) => { // Add onFAQClick as a prop here
    // Prevent the default anchor link behavior
    const handleFAQClick = (e) => {
        e.preventDefault();
        onFAQClick();
    };
    const onTopClickTop = (e) => {
        e.preventDefault();
        onTopClick();
    };


    return (
        <div className="top-menu">
            <div className="menu-logo" onClick={onTopClickTop}>ModifyME</div>
            <div className="menu-links">
                <a href="/" className="menu-link" onClick={onTopClickTop}>Home</a>
                {/* Use the handler here */}
                <a href="#faq" className="menu-link" onClick={handleFAQClick}>FAQ</a>
            </div>
        </div>
    );
}

export default TopMenu;
