import React, { useRef, useEffect, useState } from 'react';
import TopMenu from './components/TopMenu';
import GalleryForm from './components/GalleryForm';
import ImageGrid from './components/ImageGrid';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import './App.css';

import Success from './components/Success';
import Cancel from './components/Cancel';
import ErrorForm from './components/ErrorForm';


function App() {
  const [pageState, setPageState] = useState('default');
  const topRef = useRef(null);
  const faqRef = useRef(null); // Create the ref using useRef hook

  const scrollToFAQ = () => {
    // Ensure the ref current property is not null before trying to call scrollIntoView
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const session_id = queryParams.get('session_id');
    const cancelled = queryParams.get('cancelled');
    const error = queryParams.get('error');
  
    if (session_id) {
      setPageState('success');
    } else if (cancelled) {
      setPageState('cancel');
    } else if (error) {
      setPageState('error');
    }
  }, []);

  return (
    <>
        {pageState === 'success' && <Success />}
        {pageState === 'cancel' && <Cancel />}
        {pageState === 'error' && <ErrorForm />}
        {pageState === 'default' && (
          <>
          <div ref={topRef}></div>
          <div className="App">
            <TopMenu onFAQClick={scrollToFAQ} onTopClick={scrollToTop} />
            <GalleryForm />
            <ImageGrid />
            <FAQ ref={faqRef} />
            <Footer />
        <button onClick={scrollToTop} className="myBtn">
          Top
        </button>
      </div>
          </>
        )}
    </>
  );
}  

export default App;
