import React, { useState, useRef, useEffect } from 'react';
import cv from "@techstark/opencv-js";
import { Tensor, InferenceSession } from "onnxruntime-web";
import Loader from "./Loader";
import { detectImage } from "./utils/detect";
import { renderBoxes } from "./utils/renderBox";
import { download } from "./utils/download";
import './GalleryForm.css';
import AlertComponent from './AlertComponent';
import { Triangle } from 'react-loader-spinner'

function GalleryForm() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [style, setStyle] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageProcessed, setIsImageProcessed] = useState(false);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState({ text: "Loading...", progress: null });

  const [uploadedFile, setUploadedFile] = useState(null); // New state variable for the uploaded file

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [showShadowOverlay, setShowShadowOverlay] = useState(false);
  const [loadingTriangle, setLoadingTriangle] = useState(false); // State for the loading triangle

  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  const modelName = "face_detector.onnx";
  const modelInputShape = [1, 3, 640, 640];
  const topk = 100;
  const iouThreshold = 0.45;
  const scoreThreshold = 0.50;

  useEffect(() => {

    cv["onRuntimeInitialized"] = async () => {
      const baseModelURL = `${process.env.PUBLIC_URL}/model`;
      const arrBufNet = await download(`${baseModelURL}/${modelName}`, ["Loading...", setLoading]);
      const face_detector = await InferenceSession.create(arrBufNet);
      const arrBufNMS = await download(`${baseModelURL}/nms.onnx`, ["Loading...", setLoading]);
      const nms = await InferenceSession.create(arrBufNMS);

      setLoading({ text: "Warming up model...", progress: null });
      const tensor = new Tensor("float32", new Float32Array(modelInputShape.reduce((a, b) => a * b)), modelInputShape);
      await face_detector.run({ images: tensor });

      setSession({ net: face_detector, nms: nms });
      setLoading(null);
      setLoadingTriangle(false); // Hide the loading triangle
    };
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
  
    // Define allowed file extensions
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  
    if (file && allowedExtensions.exec(file.name)) {
      let reader = new FileReader();
  
      reader.onloadend = (event) => {
        setImagePreviewUrl(event.target.result);
        setIsImageUploaded(true);
        setUploadedFile(file); // Set the uploaded file
        setIsImageProcessed(false);
      };
  
      reader.readAsDataURL(file);
    } else {
      // If the file is not an image or no file is selected
      setImagePreviewUrl('');
      setIsImageUploaded(false);
      setUploadedFile(null);
      setIsImageProcessed(false);
      setAlertMessage('Invalid file type. Please upload an image file (jpg, jpeg, png).');
      setAlertVisible(true);
    }
  
    e.target.value = ''; // Reset the file input value
  };

  const performDetection = () => {
    
    console.log("Starting image processing"); // Log the start of image processing
    
    if (session && imageRef.current) {
      detectImage(
        imageRef.current,
        canvasRef.current,
        session,
        topk,
        iouThreshold,
        scoreThreshold,
        modelInputShape
      ).then((detections) => {
        const personDetections = detections.filter(detection => detection.label === 0 && detection.probability > scoreThreshold);
        if (personDetections.length === 1) {
          setShowCheckIcon(true);
          setShowShadowOverlay(true);
          setIsImageProcessed(true);
        } else {
          setAlertMessage("Invalid image. Please ensure the image has exactly one person.");
          setAlertVisible(true);
          setShowCheckIcon(false);
          setShowShadowOverlay(false);
          setImagePreviewUrl('');
          setIsImageUploaded(false);
          setIsImageProcessed(false);
        }
      }).catch((error) => {
        console.error('Detection error:', error);
        setAlertMessage("An error occurred during image processing. Please try again.");
        setAlertVisible(true);
        setImagePreviewUrl('');
        setIsImageUploaded(false);
        setIsImageProcessed(false);
      });
    } else {
      console.error('Model not loaded or Image reference not available.');
      setAlertMessage("Model not loaded or Image reference not available. Please try again later.");
      setAlertVisible(true);
      setIsImageUploaded(false);
      setIsImageProcessed(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let unmetConditions = 0;

    // Check each condition and increment unmetConditions if not met
    if (!email || email !== confirmEmail) unmetConditions++;
    if (!style) unmetConditions++;
    if (!isImageUploaded) unmetConditions++;
    // if (!isImageProcessed) unmetConditions++;

    // Check if at least two conditions are not met
    if (unmetConditions >= 2) {
        setAlertMessage('Please make sure to fill in all the fields before pressing the generate button.');
        setAlertVisible(true);
        return;
    }

    // If the code reaches here, it means fewer than two conditions are unmet
    // You can continue with the rest of the logic

    // Specific checks can still be done here for more detailed error messages
    // For example, if only one condition is not met, you can provide a specific error message

    if (unmetConditions === 1) {
        if (!email || email !== confirmEmail) {
            setAlertMessage('The email addresses are either empty or do not match. Please verify.');
            setAlertVisible(true);
            setLoadingTriangle(false); // Hide the loading triangle

            return;
        }

        if (!style) {
            setAlertMessage('Please select a style.');
            setAlertVisible(true);
            setLoadingTriangle(false); // Hide the loading triangle

            return;
        }

        if (!isImageUploaded) {
            setAlertMessage('No image uploaded. Please upload an image.');
            setAlertVisible(true);
            setLoadingTriangle(false); // Hide the loading triangle

            return;
        }

        if (!isImageProcessed) {
            setAlertMessage('Image not processed. Please wait for the image to be processed.');
            setAlertVisible(true);
            setLoadingTriangle(false); // Hide the loading triangle

            return;
        }
    }

    // Append data to formData if all conditions are met
    const formData = new FormData();
    formData.append('email', email);
    formData.append('confirmEmail', confirmEmail);
    formData.append('style', style);

    if (uploadedFile) {
        formData.append('image', uploadedFile);
    } else {
        setAlertMessage('Error in image file. Please try uploading again.');
        setAlertVisible(true);
        setLoadingTriangle(false); // Hide the loading triangle

        return;
    }
    
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('confirmEmail', confirmEmail);
      formData.append('style', style);
  
      if (uploadedFile) {
        formData.append('image', uploadedFile);
      } else {
        setAlertMessage('Error in image file. Please try uploading again.');
        setAlertVisible(true);
        setLoadingTriangle(false); // Hide the loading triangle
        return;
      }
      setLoadingTriangle(true); // Start showing the loading triangle
      // const response = await fetch('/upload', {
      //   method: 'POST',
      //   body: formData,
      // });
      
      const response = null
      if (response.ok) {
        const data = await response.json();
        if (data.file_id) {
          const checkoutResponse = await fetch('/checkout/' + data.file_id); // Corrected variable here
          if (checkoutResponse.ok) {
            const checkoutData = await checkoutResponse.json();
            window.location.href = checkoutData.url;
          } else {
            console.error('Checkout error');
            setAlertMessage('An error occurred during the checkout process. Please try again.');
            setAlertVisible(true);
            setLoadingTriangle(false); // Hide the loading triangle

          }
        } else {
          console.error('Upload error');
          setAlertMessage('An error occurred while uploading the file. Please try again.');
          setAlertVisible(true);
          setLoadingTriangle(false); // Hide the loading triangle
        }
      } else {
        console.error('Response error');
        setAlertMessage('An error occurred while submitting the form. Please try again.');
        setAlertVisible(true);
        setLoadingTriangle(false); // Hide the loading triangle
      }
    } catch (error) {
      console.error('Error:', error);
      setAlertMessage('An error occurred while submitting the form. Please try again.');
      setAlertVisible(true);
      setLoadingTriangle(false); // Hide the loading triangle
    } 
  };
  

  return (
    <div className="container">
      <AlertComponent
        message={alertMessage}
        isVisible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
      <form id="aiPhotoForm" className="photo-form" onSubmit={handleSubmit}>
        {loading && (
          <Loader text={loading.text} progress={loading.progress} />
        )}
        <div className="content-container">
          <div className="text-section">
            <h2>Start taking AI photos now.</h2>
            <p>Upload just one image of yourself, select a style and our AI will do the rest!</p>
            <p>The result will be sent to the email address you provide, the generation will take 15 minutes.</p>
            <p>Have a look at the examples below (click on the images to see the original image) and make sure to read the FAQ before purchasing.</p>
            <p>For a better result, please upload a close-up selfie with good variety and clarity. </p>
            <p>More styles/options to come, stay tuned!</p>
            <div className="input-group">
              <input type="email" id="email1" name="email1" className="email-input" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <input type="email" id="email2" name="email2" className="email-input" placeholder="Confirm your email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
              <select name="style" className="dropdown-menu" value={style} onChange={(e) => setStyle(e.target.value)}>
                <option value="">Choose a style</option>
                <option value="anime">Anime</option>
                <option value="cartoon">Cartoon</option>
                <option value="punk">Punk</option>
              </select>
            </div>
          </div>
          <div className="image-section">
            <input type="file" id="imageUpload" name="image" accept="image/*" className="visually-hidden" onChange={handleImageChange} />
            <label htmlFor="imageUpload" className={`image-placeholder ${!imagePreviewUrl ? 'without-image' : ''}`}>
              {imagePreviewUrl ? (
                <>
                 <div className="image-container" style={{ position: 'relative' }}>
                  <img 
                      ref={imageRef} 
                      src={imagePreviewUrl} 
                      alt="Image preview" 
                      onLoad={performDetection} // Ensure detection is performed when image is loaded
                  />
                  <canvas 
                      ref={canvasRef} 
                      width={640}
                      height={640}
                      style={{ 
                          position: 'absolute', 
                          top: 0, 
                          left: 0,
                          width: '100%',
                          height: '100%',
                      }}
                  ></canvas>
              </div>
                  {showCheckIcon && <i class="check-circle">&#10004;</i>}
                </>
              ) : 'Upload Image'}
            </label>
          </div>
          <button type="submit" className="cta-button">Generate (0.99€)</button>
        </div>
      </form>
      {loadingTriangle && (
          <Loader  />
      )}
    </div>
  );
}

export default GalleryForm;