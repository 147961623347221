import React from 'react';

const Success = () => {
    const goBack = () => {
        // Navigate to your main page or route
        window.location.href = '/';
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            color: 'white',
            backgroundColor: '#333',
            textAlign: 'center',
            padding: '20px',
        },
        paragraph: {
            margin: '10px 0',
            lineHeight: '1.5',
            maxWidth: '600px',
        },
        button: {
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
    };

    return (
        <div style={styles.container}>
            <h1>Payment Successful!</h1>
            <p style={styles.paragraph}>Thank you for your purchase!</p>
            <p style={styles.paragraph}>You will receive an email of confirmation.</p>
            <p style={styles.paragraph}>You will receive in around 15 minutes the result (4 generated images).</p>
            <button style={styles.button} onClick={goBack}>Go Back</button>
        </div>
    );
};

export default Success;
