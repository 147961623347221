import React from 'react';
import './Footer.css'; // Make sure to create this CSS file

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>ModifyME.ai</h3>
                    <p>Transform your photos with AI.</p>
                </div>
                {/* <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul className="footer-links">
                        <li><a href="/">Home</a></li>
                        <li><a href="#faq">FAQ</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div> */}
                {/* <div className="footer-section">
                    <h4>Follow Us</h4>
                    <div className="social-links">
                        <a href="https://instagram.com" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                        <a href="https://twitter.com" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                        <a href="https://facebook.com" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                    </div>
                </div> */}
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} ModifyME.ai. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
